<script setup lang="ts">
import { ContentSlotProps } from 'shared/types'
import { AsfBreadcrumbsProps } from '@ui/types'
import { ContentLinkProps, ContentPageProps } from '../types'

interface ComponentProps extends ContentSlotProps<ContentPageProps> {
  contentKey: string
  loading?: boolean
}

const props = defineProps<ComponentProps>()
const customerServiceContentKey = 'customer-service-asset'
const route = useRoute()
const { t } = useI18n()
const { searchContent, content: customerServiceContent } = useContent('customer-service')

const children = computed(() => props.content.at(0)?.children)
const hierarchy = computed(() => props.content.at(0)?.hierarchy)
const navigationBlockId = computed(() => props.content.at(0)?.navigationBlockId)
const title = computed(() => {
  if (props.contentKey === 'homepage') {
    return props.content.at(0)?.title || t('global.storeFrontTitle')
  }

  return props.content.at(0)?.title
})
const appearance = computed(() => {
  if (props.contentKey === 'homepage') {
    return 'homepage'
  }

  return navigationBlockId.value ? 'navigation' : 'simple'
})
const breadcrumbs = computed(() => {
  let result: AsfBreadcrumbsProps['breadcrumbs'] = []

  if (!hierarchy.value) {
    return result
  }

  const { children: hierarchyItems, hierarchyGroupId } = hierarchy.value

  if (!hierarchyItems) {
    return result
  }

  result = hierarchyItems
    .map(({ link }, index) => {
      const { label, url } = link as ContentLinkProps
      return { url, label, index }
    })
    .filter(({ url }) => route.path.match(url))
    .map(({ url, label, index }) => ({
      id: `${hierarchyGroupId}-${index}`,
      link: url,
      text: label
    }))

  return result
})

onMounted(async () => {
  if (navigationBlockId.value) {
    await searchContent({ keys: [customerServiceContentKey] })
  }
})
const customerServiceContentSlot = computed(() => customerServiceContent.value[customerServiceContentKey])
</script>
<template>
  <AsfContentPage :loading="loading" :appearance="appearance">
    <template #content-breadcrumbs>
      <AsfBreadcrumbs v-if="breadcrumbs?.length" :breadcrumbs="breadcrumbs" :home-label="$t('global.home')" />
    </template>
    <template #content-button="{ toggleMenu }">
      <AsfButton v-if="hierarchy" class="asf-button-secondary flex justify-start p-0" full-width @click="toggleMenu">
        <AsfIcon name="hamburger" class="border-r-2 px-3" />
        <span class="my-0 mx-auto">{{ $t('content.menuLabel', { name: hierarchy?.title?.text }) }}</span>
      </AsfButton>
    </template>
    <template #content-aside>
      <ContentNavigation v-if="hierarchy" :hierarchy="hierarchy" :route-path="route.path" />
    </template>
    <template #content-info>
      <ContentSlot
        v-if="customerServiceContentSlot"
        :content="customerServiceContentSlot"
        class="asf-customer-service-block"
      />
    </template>
    <template v-if="title" #content-title>
      <ContentHeading
        v-if="contentKey === 'homepage' && typeof title === 'string'"
        tag="h1"
        :text="title"
        class="sr-only"
      />
      <ContentHeading v-else v-bind="title" />
    </template>
    <template #content-main>
      <ContentSlot v-if="children?.length" :content="children" />
      <slot name="content-product-carousel" />
    </template>
  </AsfContentPage>
</template>
<style lang="postcss">
@import 'assets/css/components/account/customer-service-block.css';
</style>
